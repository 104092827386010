import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../assets/styles/pages/about.scss';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "about-us-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerTabletImage: file(relativePath: { eq: "about-us-tablet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerMobileImage: file(relativePath: { eq: "about-us-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ralphImage: file(relativePath: { eq: "ralph-about-us.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="About Us" description="A message from our Founder, Ralph van Dijk. We've commissioned 30 original audio works by diverse artists to celebrate Eardrum's 30th birthday." />
      <div className="banner-image-wrapper">
        <Link to="/">
          <Img fluid={data.bannerImage.childImageSharp.fluid} className="show-desktop" />
          <Img fluid={data.bannerTabletImage.childImageSharp.fluid} className="show-tablet" />
          <Img fluid={data.bannerMobileImage.childImageSharp.fluid} className="show-mobile" />
        </Link>
      </div>
      <div className="about-section">
        <div className="image-wrapper">
          <Img fluid={data.ralphImage.childImageSharp.fluid} />
          <strong>Ralph van Dijk</strong>
          Curator &amp; Founder
        </div>
        <div className="text-wrapper">
          <h1 className="h3">A MESSAGE FROM OUR FOUNDER</h1>
          <p>
            Every night as my three siblings and I were drifting off, my parents&nbsp;
            would crank up their
            Dutch comedy and bebop records (separate records… although comedy bebop could be a
            thing). I barely understood a word or recognised a tune but I&nbsp;
            was mesmerised and obsessed
            with trying to predict the punchlines and hooks.
          </p>
          <p>
            Forty odd years later, I’m just as obsessed with the way words,&nbsp;
            sounds and music move us.
            And I can’t wait to share the astonishing mix of audio-based art that makes up our&nbsp;
            inaugural EarFest collection.
          </p>
          <p>
            We’ve commissioned thirty original works by thirty diverse artists, to be released
            throughout the thirty days of September. A fitting way to mark&nbsp;
            <a href="http://eardrum.com/" target="_blank" rel="noreferrer">Eardrum’s 30th birthday</a>
            , but more importantly to celebrate the power of audio and help support&nbsp;
            those with the skills to harness it.
          </p>
          <p>
            The theme this year is ‘pearl’, the traditional gift for&nbsp;
            a 30th anniversary, and I love how all the
            artists have managed to weave this into their own audio gems.
          </p>
          <p>
            This is all a bit of an experiment so please
            &nbsp;
            <Link to="/">register</Link>
            &nbsp;
            and tell your&nbsp;
            friends. With any luck, we’ll
            be back next year with another exciting collection of audio art&nbsp;
            (and maybe a bit of comedy
            bebop if we’re lucky).
          </p>
          <p>
            Cheers
            <br />
            Ralph
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
